<template>
  <div>
    <Navbar page="Gestão de Incidentes" video="https://youtu.be/GoB3OlccjUU" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <button v-if="!$store.state.user.cliente" @click="novoRegistro()"  type="button" 
        :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
        class="inline-block transition duration-200 bg-yellow-300 hover:bg-yellow-400 
        focus:bg-yellow-700 focus:shadow-sm focus:ring-4 
        focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 
        rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-64 font-semibold text-center
        mb-2 md:mr-2
        "
      >
          <span class="inline-block mr-2">Registrar novo incidente</span>
      </button>

      <router-link v-if="!$store.state.user.cliente" to="/denuncias" type="button" 
        :style="{ 'background-color': $store.state.colorSecundaria, 'color': invertColor($store.state.colorSecundaria, true) }"
        class="inline-block transition duration-200 bg-yellow-300 hover:bg-yellow-400 
        focus:bg-yellow-700 focus:shadow-sm focus:ring-4 
        focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 
        rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-64 font-semibold text-center
        mb-2 md:mr-2
        "
      >
          <span class="inline-block mr-2">Acessar canal de denúncias</span>
      </router-link>

      <button @click="excluirSelecionadas" v-if="!$store.state.user.cliente && selecionados.length && !arquivados" type="button" class="transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-64 font-semibold text-center mb-2 md:mr-2">
        <span class="inline-block">Arquivar selecionadas</span>
      </button>

      <div class="grid grid-cols-12 gap-4 mb-3">
        <div class="col-span-12 md:col-span-3 mb-1 mt-1">
          <input v-model="busca" type="text" name="busca" id="busca" placeholder="Filtrando Título Incidente" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-2">
          <label for="naoContemAvaliacao" class="block text-sm font-medium mt-2">
            <input type="checkbox" v-model="arquivados" class="rounded-sm" id="naoContemAvaliacao">
            <span class="ml-2">
                Visualizar desativadas
            </span>
          </label>
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-2">
          <button @click="updateFiltro" class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer">
                        <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        REF
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Título
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Canal identificação
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Onde aconteceu
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Quando aconteceu
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Quando descobrimos
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Quando registramos
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                       Grau de risco
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                       Status
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                       Opções
                      </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="item in list" :key="item._id" :class="item.finalizado ? 'bg-green-100': '' ">
                      <td class="px-3 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                          <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                        </div>
                      </td>
                      <td class="px-2 py-3 whitespace-nowrap">
                        <div class="flex items-center">
                          <div class="text-sm_2 text-gray-500">
                            {{item.ref ? item.ref : ''}}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div class="text-sm_2 text-gray-500">
                            {{item.titulo ? item.titulo : ''}}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div class="text-sm_2 text-gray-500">
                            {{item.canal ? item.canal : 'Site'}}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div v-if="item.ondeSetor || item.ondeFornecedor" class="text-sm_2 text-gray-500">
                            {{item.tipoOnde === 'Própria empresa' ? item.ondeSetor.nome : item.ondeFornecedor.nome }}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div v-if="item.quandoOcorreu" class="text-sm_2 text-gray-500">
                            {{ item.quandoOcorreu  | moment("DD/MM/YYYY HH:mm")}}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div v-if="item.quandoDescobrimos" class="text-sm_2 text-gray-500">
                            {{ item.quandoDescobrimos  | moment("DD/MM/YYYY HH:mm")}}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div v-if="item.quandoRegistramos" class="text-sm_2 text-gray-500">
                            {{ item.quandoRegistramos  | moment("DD/MM/YYYY HH:mm")}}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div class="text-sm_2 font-medium text-gray-500" :style="{ color: item.grauDeRisco ? statusGrauDeRisco(item.grauDeRisco) : '' }">
                            {{item.grauDeRisco ? item.grauDeRisco : ''}}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div v-if="item.status === 'Pendente'" class="text-sm font-semibold text-pendente">
                              {{ item.status ? item.status: ''}}
                            </div>
                            <div v-else-if="item.status === 'Em análise'" class="text-sm font-semibold text-andamento">
                              {{ item.status ? item.status: ''}}
                            </div>
                            <div v-else class="text-sm font-semibold text-concluido">
                              {{ item.status ? item.status: ''}}
                            </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <button
                          v-if="!$store.state.user.cliente && !arquivados"
                          @click="duplicar(item._id)" 
                          type="button" 
                          class="text-white text-sm hover:bg-blue-500 bg-blue-700 rounded py-1 px-4 mx-1 mt-1">
                          Duplicar
                        </button>
                        <router-link type="button" v-if="!$store.state.user.cliente" :to="`/${route}/form/${item._id}`" class="inline-block text-sm text-white hover:bg-yellow-500 bg-yellow-400 rounded py-1 px-4 mt-1 mx-1">
                          Editar
                        </router-link>
                        <button
                          v-if="!$store.state.user.cliente && !arquivados"
                          @click="remove(item._id)" 
                          type="button" 
                          class="text-white text-sm hover:bg-red-500 bg-red-700 rounded py-1 px-4 mt-1 mx-1">
                          Arquivar
                        </button>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
    <modal name="pluginSite" :adaptive="true" :height="'auto'">
        <div class="px-5 py-8">
          <h2 class="text-2xl font-bold mb-5">Plugin para o site</h2>
          <div class="grid grid-cols-12 gap-4">

            <div class="col-span-12">
              <label for="codigo" class="block text-sm font-medium">Copie o código abaixo e cole <b class="text-blue-800">no final da body html</b> do seu site para canal de denúncia de incidentes e gerenciamento de solicitações de titulares.</label>
              <textarea v-model="codigo" type="text" name="codigo" id="codigo" rows="4" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"></textarea>
            </div>
            
          </div>

          <div class="grid grid-cols-2 mt-5">
            <div class="text-left">
                <button @click="$modal.hide('pluginSite')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Voltar
                </button>
            </div>
          </div>
        </div>
      </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: 'incidentes',
      arquivados: false,
      list: [],
      busca: "",
      selecionados: [],
      page: 1,
      perPage: 10,
      total: 0,
      query: { page: 1, limit: 10, skip: 0  },
      codigo: ""
    }
  },
  methods: {

    async novoRegistro (){
      const req = await this.$http.post(`/v1/${this.route}/`, { });
      if (req.data.success) {
          this.$router.push({ path: `/${this.route}/form/${req.data.data._id}` });
      } else {
          this.$vToastify.error(req.data.err);
      }
    },

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { busca: this.busca, arquivados: this.arquivados, skip: this.query.skip, limit: this.query.limit  });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    async getCodigo() {
      const req = await this.$http.post(`/getCodigoPlugin`);
      this.codigo = req.data;
      this.$modal.show('pluginSite');
    },

    async duplicar(incidente){
      this.$confirm({
        title: 'Duplicar incidente',
        message: `Deseja duplicar o incidente selecionado ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route}/duplicar`,{ incidente });
          this.$vToastify.success("Duplicado com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },

    async excluirSelecionadas(){
      this.$confirm({
        title: 'Arquivar selecionados',
        message: `Deseja arquivar itens selecionados ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route}/selecionados/deletar`,{ selecionados: this.selecionados });
          this.selecionados = [];
          this.$vToastify.success("Arquivadas com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },
    
    async remove(id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja arquivar esse incidente?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/${this.route}/${id}`);
              this.$vToastify.success("Removido com sucesso!");
              this.page = 1;
              this.start();
            }
          }
      });
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip, arquivados: this.arquivados, busca: this.busca };
      this.$router.push({ path: `/${this.route}`, query: this.query });
    },

    async selecionarTodos(){
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { all: true, arquivados: this.arquivados });
      this.selecionados = listReq.data.data;
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;
      this.query.arquivados = this.arquivados;

      this.$router.push({ path: `/${this.route}`, query: this.query });
    },

    statusGrauDeRisco(status){
      if(status === 'muitobaixa') return '#3a9669';
      if(status === 'baixo') return '#1b4e3b';
      if(status === 'medio') return '#f29e0c';
      if(status === 'alto') return '#dc2626';
      if(status === 'muitoalto') return '#111827';
    },
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.arquivados) this.arquivados = this.$route.query.arquivados === 'true' ? true : false;
    if(this.$route.query.busca) this.busca = this.$route.query.busca;
    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.arquivados) this.arquivados = this.$route.query.arquivados === 'true' ? true : false;
    if(this.$route.query.busca) this.busca = this.$route.query.busca;
    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.$watch('arquivados', async (arquivados) => {
      this.query.skip = parseInt(0);
      this.query.arquivados = arquivados;
      this.$router.push({ path: `/${this.route}`, query: this.query });
    });

    this.start(); 
  },
}
</script>